import React, { useState, useEffect } from 'react';
import { useLocation, NavLink, Link } from 'react-router-dom';
import { Image, Avatar } from '@chakra-ui/react';
import { Progress } from '@chakra-ui/react';
import sideImage from '../assets/images/sideimage.png';
import { useProducts } from './compare/ProductsProvider';
import { useAuth } from './user/AuthProvider';
import axiosInstance,{updateToken} from '../utils/axio-instance';
import { useTranslation } from 'react-i18next'; 
import NavLinks from './user/NavLinks';
import HeaderLinks from './user/HeaderLinks';
import HomeLinks from './user/HomeLinks';
import MenuHeader from './user/MenuHeader';

const SideMenu = ({ }) => {

  const { t, i18n } = useTranslation();

  // const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  // const [isLanguagePopupOpen, setIsLanguagePopupOpen] = useState(false);
  // const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const { selectedProducts, groupProductCounts } = useProducts();

  const { user, setUser } = useAuth();

  const [userInfo, setUserInfo] = useState(null);
  const [profileInfo, setProfileInfo] = useState(null);

  useEffect(() => {
    loadUser();
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        } else {
          return prevProgress + 1;
        }
      });
    }, 50); // Adjust the interval as needed for the desired loading speed

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    loadUser();
  }, [localStorage.getItem('userid')]);

const loadUser = ()=>{
 console.log("Load user info from local storage");
  const storedUserInfo = localStorage.getItem('userid');
  setUserInfo(storedUserInfo);

  if (storedUserInfo && profileInfo==null) {
    updateToken(localStorage.getItem('token'));
    // Make your profile API call here
    axiosInstance.get(`/users/${storedUserInfo}/profile/`)
    .then(response => {
      // Handle profile API response
      const storedProfileInfo =  response.data;
      setProfileInfo(storedProfileInfo);
      setUser({
        ...user, ...storedProfileInfo
      });
    })
    .catch(error => {
      // Handle profile API error
      console.error('Profile API error:', error);
      localStorage.removeItem('userid');
      setUserInfo(null);
    });
  }
}

  const drawerClose = () => {
    document.getElementById("my-drawer-4").checked = false;
  };
  
  const roles = localStorage.getItem('roles');

  return (
    <>
      {progress < 100 && (
        <Progress
          colorScheme="black"
          size="xs"
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="1px"
          zIndex="1000"
          value={progress}
        />
      )}
      <div className="font-linksans sticky top-0 z-50 bg-lightBg bg-[#FAFBFC]">
      <div className="container mx-auto py-4">
        <div className="flex items-center justify-between">
          <div className="flex gap-8 items-center ">
            <NavLink to={"/"}  className="flex items-center space-x-2" onClick={() => window.scrollTo(0, 0)}>
            <Image 
                    mt={1}
                    width={280}
                    src={"/knockthedoor-logo-1-g-275x55.webp"} 
                    className="2xl:text-4xl xl:text-4xl lg:text-3xl md:text-3xl text-2xl font-bold"
                    alt='KnockTheDoor' />
             {/* <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:text-3xl text-2xl font-bold font-moneta mt-2.5 text-[#32405b]">
                KnockTheDoor
              </h3> */}
            </NavLink>
            {/* navlink hover dropdown */}
            <div className="hidden md:flex">
            <MenuHeader/>
            </div>
          </div>
          <div className="flex items-center gap-[20px] sm:gap-[22px] md:gap-[22px] lg:gap-[42px] xl:gap-[42px]">

            {(roles === 'buyergrouprentals' || roles === 'buyers') && (
              <>
              <div className="flex items-center gap-6">
              {userInfo ? (
                    <>
                {groupProductCounts !== null && (
                <div className="relative cursor-pointer">
                  <div className="dropdown dropdown-bottom dropdown-end">
                  <Link to="/shortlisted/">
                    <div tabIndex={0} className="">
                      <span className="w-10 h-4 flex text-xs -right-4 -top-4 justify-center items-center absolute rounded-full bg-[#FFAB00] p-2">
                      {(groupProductCounts?.total||0)}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21.53 14.5292L16.53 19.5292C16.4613 19.6029 16.3785 19.662 16.2865 19.703C16.1945 19.744 16.0952 19.766 15.9945 19.7678C15.8938 19.7695 15.7937 19.751 15.7004 19.7133C15.607 19.6756 15.5221 19.6194 15.4509 19.5482C15.3797 19.477 15.3235 19.3922 15.2858 19.2988C15.2481 19.2054 15.2296 19.1054 15.2314 19.0047C15.2331 18.904 15.2552 18.8046 15.2962 18.7126C15.3372 18.6206 15.3963 18.5378 15.47 18.4692L19.1889 14.7492H5.99995C5.80104 14.7492 5.61027 14.6702 5.46962 14.5295C5.32897 14.3889 5.24995 14.1981 5.24995 13.9992C5.24995 13.8003 5.32897 13.6095 5.46962 13.4688C5.61027 13.3282 5.80104 13.2492 5.99995 13.2492H21C21.1482 13.2493 21.2931 13.2934 21.4163 13.3758C21.5395 13.4582 21.6355 13.5753 21.6922 13.7123C21.7489 13.8492 21.7638 13.9999 21.7349 14.1453C21.706 14.2907 21.6347 14.4243 21.53 14.5292ZM18.75 8.99918C18.75 8.80026 18.6709 8.6095 18.5303 8.46885C18.3896 8.3282 18.1989 8.24918 18 8.24918H4.81095L8.52995 4.52918C8.60364 4.46052 8.66274 4.37771 8.70373 4.28572C8.74472 4.19372 8.76677 4.0944 8.76854 3.9937C8.77032 3.893 8.75179 3.79297 8.71407 3.69958C8.67635 3.60619 8.62021 3.52136 8.54899 3.45014C8.47777 3.37892 8.39294 3.32278 8.29955 3.28505C8.20616 3.24733 8.10613 3.22881 8.00543 3.23059C7.90473 3.23236 7.80541 3.2544 7.71341 3.2954C7.62141 3.33639 7.53861 3.39549 7.46995 3.46918L2.46995 8.46918C2.36519 8.57406 2.29387 8.70764 2.26499 8.85304C2.2361 8.99844 2.25095 9.14914 2.30766 9.2861C2.36438 9.42306 2.4604 9.54015 2.58362 9.62257C2.70683 9.70499 2.85171 9.74905 2.99995 9.74918H18C18.1989 9.74918 18.3896 9.67016 18.5303 9.52951C18.6709 9.38885 18.75 9.19809 18.75 8.99918Z"
                          fill="#111518"
                        />
                      </svg>
                    </div>
                    </Link>
                  </div>
                </div>
                  )}
                  </>
                ) : (
                  <>
            {selectedProducts && selectedProducts.length > 0 && (
                <div className="relative cursor-pointer">
                <div className="dropdown dropdown-bottom dropdown-end">
                <Link to="/shortlisted/">
                  <div tabIndex={0} className="">
                    <span className="w-10 h-4 flex text-xs -right-4 -top-4 justify-center items-center absolute rounded-full bg-[#FFAB00] p-2">
                    {selectedProducts.length}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M21.53 14.5292L16.53 19.5292C16.4613 19.6029 16.3785 19.662 16.2865 19.703C16.1945 19.744 16.0952 19.766 15.9945 19.7678C15.8938 19.7695 15.7937 19.751 15.7004 19.7133C15.607 19.6756 15.5221 19.6194 15.4509 19.5482C15.3797 19.477 15.3235 19.3922 15.2858 19.2988C15.2481 19.2054 15.2296 19.1054 15.2314 19.0047C15.2331 18.904 15.2552 18.8046 15.2962 18.7126C15.3372 18.6206 15.3963 18.5378 15.47 18.4692L19.1889 14.7492H5.99995C5.80104 14.7492 5.61027 14.6702 5.46962 14.5295C5.32897 14.3889 5.24995 14.1981 5.24995 13.9992C5.24995 13.8003 5.32897 13.6095 5.46962 13.4688C5.61027 13.3282 5.80104 13.2492 5.99995 13.2492H21C21.1482 13.2493 21.2931 13.2934 21.4163 13.3758C21.5395 13.4582 21.6355 13.5753 21.6922 13.7123C21.7489 13.8492 21.7638 13.9999 21.7349 14.1453C21.706 14.2907 21.6347 14.4243 21.53 14.5292ZM18.75 8.99918C18.75 8.80026 18.6709 8.6095 18.5303 8.46885C18.3896 8.3282 18.1989 8.24918 18 8.24918H4.81095L8.52995 4.52918C8.60364 4.46052 8.66274 4.37771 8.70373 4.28572C8.74472 4.19372 8.76677 4.0944 8.76854 3.9937C8.77032 3.893 8.75179 3.79297 8.71407 3.69958C8.67635 3.60619 8.62021 3.52136 8.54899 3.45014C8.47777 3.37892 8.39294 3.32278 8.29955 3.28505C8.20616 3.24733 8.10613 3.22881 8.00543 3.23059C7.90473 3.23236 7.80541 3.2544 7.71341 3.2954C7.62141 3.33639 7.53861 3.39549 7.46995 3.46918L2.46995 8.46918C2.36519 8.57406 2.29387 8.70764 2.26499 8.85304C2.2361 8.99844 2.25095 9.14914 2.30766 9.2861C2.36438 9.42306 2.4604 9.54015 2.58362 9.62257C2.70683 9.70499 2.85171 9.74905 2.99995 9.74918H18C18.1989 9.74918 18.3896 9.67016 18.5303 9.52951C18.6709 9.38885 18.75 9.19809 18.75 8.99918Z"
                        fill="#111518"
                      />
                    </svg>
                  </div>
                  </Link>
                </div>
              </div>
                )}
                </>
                )}
              </div>
              </>
              )}
          
            {userInfo ? 
                  <>
              {/* profile */}
            <div className="flex hidden xl:flex lg:flex gap-2 items-center">
              <div className="w-12 h-12 overflow-hidden bg-gray-200 rounded-full">
              <Avatar className="w-full h-full" name={`${profileInfo?.first_name.charAt(0).toUpperCase()}${profileInfo?.last_name.charAt(0).toUpperCase()}`} />
              </div>
              <div className="flex flex-col gap-px">
                <h4 className="text-lg font-medium text-paragraphColor">
                {profileInfo?.first_name && profileInfo?.last_name && (
                    <>
                      {profileInfo.first_name.charAt(0).toUpperCase() + profileInfo.first_name.slice(1).toLowerCase()}{" "}
                      {profileInfo.last_name.charAt(0).toUpperCase() + profileInfo.last_name.slice(1).toLowerCase()}
                    </>
                  )}
                </h4>
                <span className="text-sm  text-paragraphColor">
                {profileInfo?.roles?.map((role) => (
                  <span key={role}>
                    {role.charAt(0).toUpperCase() + role.slice(1).toLowerCase()}
                  </span>
                ))}
                </span>
              </div>
            </div>
            </>
            :<>
              {/* login and signup button */}
              <div className="gap-5 2xl:d-flex xl:d-flex lg:d-flex md:d-flex items-center  ml-[5px]">
              <NavLink to={"/sign-up/"}>
              <button className="text-[#060605] hover:bg-borderColor transition-all duration-300 text-sm md:text-base px-2 md:px-2 py-2 md:py-3 rounded-lg border border-borderColor">
               {t('signup')}
                </button>
              </NavLink>
            </div>
            </>
             }

            {/* drawer */}
            <div>
              <div className="drawer drawer-end w-10 sm:w-12 md:w-14 lg:w-16 xl:w-16">
                <input
                  id="my-drawer-4"
                  type="checkbox"
                  className="drawer-toggle"
                />
                <div className="drawer-content border flex items-center rounded-lg px-2 py-2 sm:px-4 sm:py-3">
                  {/* Page content here */}
                  <label
                    htmlFor="my-drawer-4"
                    className="rounded-lg cursor-pointer  "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      className="w-6 h-6"
                    >
                      <path
                        d="M3 6.5H21"
                        stroke="#111518"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M6.5 12.5L21 12.5"
                        stroke="#111518"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M10 18.5L21 18.5"
                        stroke="#111518"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </label>
                </div>
                <div className="drawer-side z-50">
                  <label
                    htmlFor="my-drawer-4"
                    aria-label="close sidebar"
                    className="drawer-overlay"
                  ></label>
                  <ul className="menu bg-base-200 text-base-content min-h-full w-80 p-4">
                    {/* Sidebar content here */}
                    <div className="flex flex-col justify-center gap-4">

                    <Link to={'/'} className="text-lg md:text-lg text-textColor text-center pb-4 border-b border-gray-300 items-center flex justify-center space-x-2">
                    <Image 
                      pt="1"
                      width="35px"
                      height="35px"
                      src={sideImage} 
                      alt='KnockTheDoor' 
                      />
                       <span className='md:hidden mt-7'>{profileInfo?.first_name}</span>
                    </Link>
                    {userInfo ? 
                      <>
                      <div className="w-full justify-center flex flex-col ">
                      <NavLinks profileInfo={profileInfo} onClose={drawerClose}/>
                      </div>
                      </>
                      :<>
                      <div className="w-full justify-center flex flex-col ">
                       <HomeLinks onClose={drawerClose}/>
                      </div>
                      </>
                      }
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {userInfo &&
        <div className="hidden flex xl:flex  2xl:flex w-full pt-4 justify-end">
          <div className="flex gap-4 items-center">
            <HeaderLinks profileInfo={profileInfo}/>
          </div>
        </div>
         }
      </div>
    </div>
    </>
  );
};


export default SideMenu;