import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Input, Button, Flex, FormControl, FormLabel, FormErrorMessage, useToast, Badge } from '@chakra-ui/react'; // Import Chakra UI components
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next'; 

const BuilderInfo = () => {
  const userId = localStorage.getItem('userid');
  const [builderInfo, setBuilderInfo] = useState({
    builderName: '',
    builderHQEmail: '',
    builderHQPhone: '',
    builderAddress: '',
    companyWebsite: ''
  });

  const [errors, setErrors] = useState({});
  const location = useLocation();
  const toast = useToast();
  const { t} = useTranslation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(()=> {
    getBuilderInfo();
  },[]);
  useEffect(() => {
    if (location.pathname.indexOf("builder_info") > 0) {
      getBuilderInfo();
    }
  }, [location.pathname]);

  const validateFields = () => {
    const newErrors = {};
    if (!builderInfo.builderName) {
      newErrors.builderName = "Builder Name is required.";
    }
    if (!builderInfo.builderHQEmail || !validateEmail(builderInfo.builderHQEmail)) {
      newErrors.builderHQEmail = "Valid HQ Email is required.";
    }
    if (builderInfo.builderHQPhone && !validatePhone(builderInfo.builderHQPhone)) {
      newErrors.builderHQPhone = "Invalid phone format. Please use +[country code][number].";
    }
  
    if (!builderInfo.builderAddress || builderInfo.builderAddress.trim().length < 10) {
      newErrors.builderAddress = "Builder Address must be at least 10 characters long.";
  }

    if (!builderInfo.companyWebsite || !validateWebsite(builderInfo.companyWebsite)) {
      newErrors.companyWebsite = "Invalid company website URL.";
    }
    return newErrors;
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+\d{1,3}\d{1,14}$/; // Simple phone validation regex
    return phoneRegex.test(phone);
  };

  const validateWebsite = (website) => {
    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    return regex.test(website);
  };

  const getBuilderInfo = () => {
    console.log("get builderinfo")
    axios.get(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}/builder-info/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setBuilderInfo({ ...response.data });
      })
      .catch(error => {
        console.error('Error in loading getBuilderInfo:', error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields();
    setSuccessMessage('');
    setErrorMessage('');
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set the validation errors
      return;
    }

    axios.put(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}/builder-info/`, builderInfo, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setSuccessMessage(t('saved'));
        toast({
          title: t('builderinfo-updatemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      })
      .catch(error => {
        console.error('Error updating profile:', error);
        setErrorMessage(t('failed'));
        toast({
          title: 'Error',
          description: t('builderinfo-failedmessage'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Update the builderInfo state
    setBuilderInfo({ ...builderInfo, [name]: value });
  
    // Clear specific field errors when the input is valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
  
      // Validate each field when input changes and remove the error if it's valid
      if (name === 'builderName' && value) {
        delete newErrors.builderName;
      } else if (name === 'builderHQEmail' && validateEmail(value)) {
        delete newErrors.builderHQEmail;
      } else if (name === 'builderHQPhone' && validatePhone(value)) {
        delete newErrors.builderHQPhone;
      } else if (name === 'builderAddress' && value.trim().length >= 10) {
        delete newErrors.builderAddress;
      } else if (name === 'companyWebsite' && validateWebsite(value)) {
        delete newErrors.companyWebsite;
      }
  
      return newErrors;
    });
  };
  

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [successMessage, errorMessage]);

  return (
    <div className="bg-primary font-linksans min-h-screen">
  <div className="container px-5 py-12">
    <div className="flex flex-col items-center justify-center">
    <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
          Builder Information
        </h3>
      <div className="w-full mb-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          {/* First Column */}
          <div className="w-full">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Builder Name <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                  name="builderName"
                  placeholder="Enter Builder Name"
                  value={builderInfo.builderName}
                  onChange={handleInputChange}
                />
                {errors.builderName && (
                  <span className="text-sm text-red-500">{errors.builderName}</span>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Builder HQ Email <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                  name="builderHQEmail"
                  type="email"
                  placeholder="Enter Builder HQ Email"
                  value={builderInfo.builderHQEmail}
                  onChange={handleInputChange}
                />
                {errors.builderHQEmail && (
                  <span className="text-sm text-red-500">{errors.builderHQEmail}</span>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Builder HQ Phone
                </label>
                <input
                  className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                  name="builderHQPhone"
                  type="tel"
                  placeholder="Enter Builder HQ Phone"
                  value={builderInfo.builderHQPhone}
                  onChange={handleInputChange}
                />
                {errors.builderHQPhone && (
                  <span className="text-sm text-red-500">{errors.builderHQPhone}</span>
                )}
              </div>
            </form>
          </div>

          {/* Second Column */}
          <div className="w-full">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Builder Address <span className="text-red-500">*</span>
              </label>
              <input
                className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                name="builderAddress"
                placeholder="Enter Builder Address"
                value={builderInfo.builderAddress}
                onChange={handleInputChange}
              />
              {errors.builderAddress && (
                <span className="text-sm text-red-500">{errors.builderAddress}</span>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Company Website <span className="text-red-500">*</span>
              </label>
              <input
                className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                name="companyWebsite"
                placeholder="Enter Website URL"
                value={builderInfo.companyWebsite}
                onChange={handleInputChange}
              />
              {errors.companyWebsite && (
                <span className="text-sm text-red-500">{errors.companyWebsite}</span>
              )}
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className="w-full max-w-xs text-sm py-3 rounded-lg font-semibold text-gray-700 bg-white border hover:bg-gray-100 focus:ring focus:ring-blue-500"
            onClick={handleSubmit}
          >
            Update Builder Info
          </button>
        </div>

        {/* Success and Error Messages */}
        <div className="mt-4">
          {successMessage && (
            <div className="flex items-center bg-green-100 text-green-800 px-4 py-2 rounded-md">
              <CheckIcon className="w-4 h-4 mr-2" />
              <span className="text-sm">
                {successMessage.charAt(0).toUpperCase() + successMessage.slice(1).toLowerCase()}
              </span>
            </div>
          )}
          {errorMessage && (
            <div className="flex items-center bg-red-100 text-red-800 px-4 py-2 rounded-md mt-2">
              <CloseIcon className="w-4 h-4 mr-2" />
              <span className="text-sm">
                {errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default BuilderInfo;
