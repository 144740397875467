import { Text } from '@chakra-ui/react';
import { format, formatDistanceToNow, isToday, isYesterday } from 'date-fns';

export function formatDateHumanReadable(date) {
  const parsedDate = new Date(date);

  if (isToday(parsedDate)) {
    // For today, show relative time
    const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });
    return timeAgo; // e.g., "2 hours ago" or "10 minutes ago"
  }

  if (isYesterday(parsedDate)) {
    return 'Yesterday'; // For yesterday, display "Yesterday"
  }

  // For older dates, format as "dd MMM, yyyy"
  return format(parsedDate, 'dd MMM, yyyy'); // e.g., "18 Nov, 2024"
}

const DateDisplay = (props) => {
    return <Text title={format(props.date, 'dd MMM, yyyy hh:mm a')} className={props.className}>{formatDateHumanReadable(props.date)}</Text>
};

export default DateDisplay;