import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Input, Button, Flex, Select, useToast, Badge, FormLabel, FormControl } from '@chakra-ui/react'; // Import Chakra UI components

import axiosInstance from '../../utils/axio-instance.js';
import {states} from '../../data/us_states.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next'; 

const AgentLicenseInfo = ({profileInfo}) => { 
  const userId = localStorage.getItem('userid');
  const [licenseInfo, setLicenseInfo] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const { t} = useTranslation();


  const localtion = useLocation();

  // useEffect to set the state when profileInfo changes
  useEffect(() => {
    if(localtion.pathname.indexOf("agent-licence-info")>0){
      getLicenseInfo();
    }
  }, [localtion.pathname]);

  const isValidObject = ()=>{
      let error = {}, ecount = 0;
      if(!licenseInfo.brokerEmail ||  !validateEmail(licenseInfo.brokerEmail)){
        error.brokerEmail = 'Broker Email is required';
        ecount++;
      }
      if(!licenseInfo.brokerName || (licenseInfo.brokerName) === ''){
        error.brokerName = "Broker Name is required"; ecount++;
      }
      if(!licenseInfo.brokerPhone ||  (licenseInfo.brokerPhone) ===''){
        error.brokerPhone = "Broker Phone is required"; ecount++;
      }
      if(!licenseInfo.brokerAddress ||  (licenseInfo.brokerAddress) === ''){
        error.brokerAddress = "Broker Address is required"; ecount++;
      }

      if(!licenseInfo.state){
        error.State = "State is mandatory"; ecount++;
      }
      if(!licenseInfo.licenseNo){
        error.licenseNo = "License No. is mandatory"; ecount++;
      }
      if(!licenseInfo.narNumber){
        error.narNumber = "Realtor NAR Number  is mandatory"; ecount++;
      }
      if(!licenseInfo.address){
        error.address = ("Mailing Address is mandatory"); ecount++;
      }
      if(ecount>0){
        setErrors(error);
        toast({
          title: 'Error',
          description: "Invalid values, Fill all mandatory fields and try again!",
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
        throw new Error("invalid values");
      }
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const getLicenseInfo = ()=>{
    // Send updated profile information to the server using Axios
    axiosInstance.get(`/users/${userId}/agent-license/`)
    .then(response => {
      setLicenseInfo({...response.data});
      })
    .catch(error => {
      console.error('Error in loading getLicenseInfo:', error);
    });
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setErrors({});
     try{
      isValidObject();
   
    // Send updated profile information to the server using Axios
    axiosInstance.put(`/users/${userId}/agent-license/`, licenseInfo)
    .then(response => {
      setSuccessMessage(t('saved'));
        toast({
          title: t('licenceinfo-updatemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      })
    .catch(error => {
      console.error('Error updating profile:', error);
      let errorMessage = t('licenceinfo-failedmessage');
      if (error.response && error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
      }
      setErrorMessage(t('failed'));
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    });
    }catch(e){
      console.log(e);
    }
  };
  const handleKeyEvent = (e)=>{
    //console.log(e.target.value, e.target.name);
    setLicenseInfo({...licenseInfo, [e.target.name]: e.target.value});
    e.preventDefault();
  }
  const setState= (e)=>{
    setLicenseInfo({...licenseInfo, state: e.target.value});
  }

  return (
    <div className="bg-primary font-linksans">
  <div className="container px-5 py-12 min-h-svh">
  <div className="flex-col items-center flex justify-center">
  <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
        Agent Licence Information
        </h3>
        </div>
    <div className="top-sec w-full pb-10">
      <form onSubmit={handleSubmit}>
        {/* Responsive grid layout */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          {/* Left Column */}
          <div className="w-full">
            <div className="mb-4">
              <label className="block font-medium">
                Licensed State <span className="text-red-500">*</span>
              </label>
              <select
                name="state"
                value={licenseInfo.state}
                onChange={setState}
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                <option value="">Select State</option>
                {states.map((st) => (
                  <option key={st.abbreviation} value={st.abbreviation}>
                    {st.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                State License Number <span className="text-red-500">*</span>
              </label>
              <input
                name="licenseNo"
                type="number"
                value={licenseInfo.licenseNo}
                onChange={handleKeyEvent}
                placeholder="License Number"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Realtor NAR Number <span className="text-red-500">*</span>
              </label>
              <input
                name="narNumber"
                type="number"
                value={licenseInfo.narNumber}
                onChange={handleKeyEvent}
                placeholder="Realtor NAR Number"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Mailing Address <span className="text-red-500">*</span>
              </label>
              <input
                name="address"
                type="text"
                value={licenseInfo.address}
                onChange={handleKeyEvent}
                placeholder="Mailing Address"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
          </div>

          {/* Right Column */}
          <div className="w-full">
            <div className="mb-4">
              <label className="block font-medium">
                Broker Name <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerName"
                type="text"
                value={licenseInfo.brokerName}
                onChange={handleKeyEvent}
                placeholder="Broker Name"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Broker Email <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerEmail"
                type="email"
                value={licenseInfo.brokerEmail}
                onChange={handleKeyEvent}
                placeholder="Broker Email"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Broker Phone <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerPhone"
                type="tel"
                value={licenseInfo.brokerPhone}
                onChange={handleKeyEvent}
                placeholder="Broker Phone"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="block font-medium">
                Broker Office Address <span className="text-red-500">*</span>
              </label>
              <input
                name="brokerAddress"
                type="text"
                value={licenseInfo.brokerAddress}
                onChange={handleKeyEvent}
                placeholder="Broker Office Address"
                className="w-full bg-white border border-gray-300 rounded-lg px-3 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className="bg-white text-black font-semibold px-6 py-3 rounded-lg hover:bg-blue-600 transition"
            onClick={handleSubmit}
          >
            Update Agent License Info
          </button>
          {successMessage && (
            <span className="ml-4 px-4 py-2 bg-green-100 text-green-700 rounded-lg">
              {successMessage.charAt(0).toUpperCase() +
                successMessage.slice(1).toLowerCase()}
            </span>
          )}
          {errorMessage && (
            <span className="ml-4 px-4 py-2 bg-red-100 text-red-700 rounded-lg">
              {errorMessage.charAt(0).toUpperCase() +
                errorMessage.slice(1).toLowerCase()}
            </span>
          )}
        </div>
      </form>
    </div>
  </div>
</div>
  );
};

export default AgentLicenseInfo;
