import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import qs from 'qs';

function GroupBuyerGetStarted() {
  const { id } = useParams();
  const [cn, setCN] = useState("");
  useEffect(()=>{
   // alert(id);

    const urlParams = qs.parse(window.location.search.slice(1));
    const { cn } = urlParams;
    if(cn)
      setCN(cn);
  }, [])
  return (
    <div className="font-linksans">
        <iframe
        id="JotFormIFrame-242835918839170"
        title="Prod-Group-Buyers-1"
        onload="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allow="geolocation; microphone; camera; fullscreen"
        src={`https://form.jotform.com/242835918839170?howCan[field_9]=${cn}&CompanyId=${id}`}
        frameborder="0"
        style={{"minWidth":"100%", maxWidth: "100%", "height":"800px", "border":"none"}}
        scrolling="no"
        >
        </iframe>

    </div>
  )
}

export default GroupBuyerGetStarted