import { useEffect, useState } from "react";
import stillWhyImg2560x760 from "../../../assets/images/vetted-process-groupbuyers-1a.webp";
import stillWhyImg1685x400 from "../../../assets/images/vetted-process-groupbuyers-1a.webp";
import stillWhyImg800x400 from "../../../assets/images/vetted-process-groupbuyers-1a.webp";


function StillWhy() {

  const [backgroundImage, setBackgroundImage] = useState(stillWhyImg2560x760);

  useEffect(() => {
    const updateBackgroundImage = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 2560) {
        setBackgroundImage(stillWhyImg2560x760);
      } else if (screenWidth >= 1920) {
        setBackgroundImage(stillWhyImg2560x760);
      } else if (screenWidth >= 1685) {
        setBackgroundImage(stillWhyImg1685x400);
      } else if (screenWidth >= 800) {
        setBackgroundImage(stillWhyImg800x400);
      } else {
        setBackgroundImage(stillWhyImg2560x760); // Default image or the closest fit
      }
    };

    // Initial background image setting
    updateBackgroundImage();

    // Update on window resize
    window.addEventListener("resize", updateBackgroundImage);

    // Clean up listener on component unmount
    return () => window.removeEventListener("resize", updateBackgroundImage);
  }, []);

  return (
    <div id="still-why" className="container mx-auto 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8">
      <div>
        <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl font-semibold text-center text-textColor pb-5">
        Still, why?
        </h3>
        <div className="flex justify-center items-center">
        <div className="w-full max-w-2xl h-auto font-linksans">
          <img
            src={backgroundImage}
            alt="Still Why Background"
            className="w-full h-full object-cover"
          />
        </div>
    </div>
    </div>
    </div>
  );
}

export default StillWhy;
