import React, { useMemo, useState, useEffect } from "react";
import { useReactTable, getCoreRowModel, flexRender, getFilteredRowModel, getPaginationRowModel } from "@tanstack/react-table";
import axiosInstance,{axiosInstanceES} from "../../utils/axio-instance";
import { Button, useToast, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Text, Select,useDisclosure} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next'; 
import Swal from "sweetalert2";
import CommunityUploadModal from '../grid/CommunityUploadModal';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {states} from '../../data/us_states.js';


const CommunityTable = () => {
  
  const roleInfo = localStorage.getItem('roles');  
  const storedUserInfo = localStorage.getItem('userid');

  let apiUrl;
  // State management
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [newRow, setNewRow] = useState({});
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [focusedField, setFocusedField] = useState(null);
  
  const toast = useToast();  
  const { t} = useTranslation();
  

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [validationErrors, setValidationErrors] = React.useState({});

  const fetchData = async () => {

    const isAdminOrStaff = roleInfo && (roleInfo.includes('admin') || roleInfo.includes('staff'));
    if (isAdminOrStaff) {
    apiUrl = `/listcommunitieswithpagination`;
    } else {
    const userAuthorization = storedUserInfo; 
    apiUrl = `/getcommunitiesdatabyuserid?userId=${userAuthorization}`;
      }

    setLoading(true);
    try {
      const response = await axiosInstanceES.get(apiUrl, {
        params: {
          start: pageIndex*pageSize,
          size: pageSize,
          filters: columnFilters.reduce((acc, filter) => {
            acc[filter.id] = filter.value;
            return acc;
          }, {}),
        },
      });

      //const { data: rows, totalCount } = response.data;
      setData(response.data.rows);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  // Fetch data from server
  useEffect(() => {
    fetchData();
  }, [apiUrl, pageIndex, pageSize, columnFilters]);


  // Define columns
  const columns = useMemo(
    () => [
      {
        id: 'actions',
        header: "Actions",
        cell: ({ row }) => {
          const isEditing = editRowId === row.original.communityPublicID || row.original.isNewRow;

          if (isEditing) {
            return row.original.isNewRow ? (
              <div className="flex flex-wrap gap-2 justify-center">
                <Button
                 onClick={() => handleSave(row)}
                  colorScheme="green"
                  style={{ marginRight: "8px", padding: "4px 8px" }}
                  size="sm"
                >
                  Save
                </Button>
                <Button
                  onClick={() => handleCancel(row.original)}
                  colorScheme="red"
                  style={{ padding: "4px 8px" }}
                  size="sm"
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="flex flex-wrap gap-2 justify-center">
                <Button
                  onClick={() => handleUpdate(row)}
                  colorScheme="blue"
                  style={{ marginRight: "8px", padding: "4px 8px" }}
                  size="sm"
                >
                  Update
                </Button>
                <Button
                  onClick={() => handleCancel(row.original)}
                  colorScheme="red"
                  style={{ padding: "4px 8px" }}
                  size="sm"
                >
                  Cancel
                </Button>
              </div>
            );
          }
  
          return (
            <div className="flex flex-wrap gap-2 justify-center">
              <Button
                onClick={() => handleEdit(row.original)}
                style={{ marginRight: "8px", padding: "4px 8px" }}
                size="sm"
              >
                Edit
              </Button>
              <Button
                onClick={() => handleDelete(row.original.communityPublicID)}
                style={{ marginRight: "8px",padding: "4px 8px" }}
                size="sm"
              >
                Delete
              </Button>

              <Button
                onClick={() => handleUploadClick(row.original.communityPublicID)}
                style={{ padding: "4px 8px" }}
                size="sm"
              >
                Photos
              </Button>
            </div>
          );
        },
      },
      {
        accessorKey: "communityName",
        header: "Community Name",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by Community Name"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "communityName"} 
            onFocus={() => setFocusedField("communityName")} 
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.communityPublicID ? (
            <input
              type="text"
              value={editRowData.communityName || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, communityName: e.target.value }))
              }
              placeholder="Edit Community Name"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "communityName"} 
              onFocus={() => setFocusedField("communityName")} 
            />
          ) : (
            <span className="cursor-pointer hover:underline" onClick={() => window.open(`/community/en/${row.original.state}/${row.original.city}/${row.original.communityPublicID}`, '_blank')}>{row.original.communityName}</span>
          ),
      },
      { 
        accessorKey: "builderName",
        header: "Builder Name",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by Builder Name"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "builderName"} 
            onFocus={() => setFocusedField("builderName")} 
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.communityPublicID ? (
            <input
              type="text"
              value={editRowData.builderName || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, builderName: e.target.value }))
              }
              placeholder="Edit Builder Name"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "builderName"} 
              onFocus={() => setFocusedField("builderName")} 
            />
          ) : (
            row.original.builderName
          ),
      },
      { 
        accessorKey: "street", 
        header: "Street", 
        cell: ({ row }) =>
          editRowId === row.original.communityPublicID ? (
            <input
              type="text"
              value={editRowData.street || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, street: e.target.value }))
              }
              placeholder="Edit Street Name"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "street"} 
              onFocus={() => setFocusedField("street")} 
            />
          ) : (
            row.original.street
          ),
      },
      { 
        accessorKey: "city",
        header: "City",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by City"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "city"} 
            onFocus={() => setFocusedField("city")} 
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.communityPublicID ? (
            <input
              type="text"
              value={editRowData.city || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, city: e.target.value }))
              }
              placeholder="Edit City Name"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "city"} 
              onFocus={() => setFocusedField("city")} 
            />
          ) : (
            row.original.city
          ),
      },
      { 
        accessorKey: "state", 
        header: "State",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by State"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "state"} 
            onFocus={() => setFocusedField("state")} 
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.communityPublicID ? (
            <select
              value={editRowData.state || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, state: e.target.value }))
              }
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "state"}
              onFocus={() => setFocusedField("state")}
            >
              <option value="" disabled>
                Select a state
              </option>
              {states.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </select>
          ) : (
            row.original.state
          ),
      },
      { 
        accessorKey: "zip",
        header: "Zip",
        cell: ({ row }) =>
          editRowId === row.original.communityPublicID ? (
            <input
              type="text"
              value={editRowData.zip || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, zip: e.target.value }))
              }
              placeholder="Edit Zip code"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus={focusedField === "zip"} 
              onFocus={() => setFocusedField("zip")} 
            />
          ) : (
            row.original.zip
          ),
      },
      // {
      //   accessorKey: "address",
      //   header: "Full Address",
      //   cell: ({ row }) => {
      //     const { street, city, state, zip } = row.original;
      //     return [street, city, state, zip].filter(Boolean).join(", ");
      //   },
      // },
    ],
    [editRowId, editRowData, newRow]
  );

  const table = useReactTable({
    data,
    columns,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
    pageCount: Math.ceil(data.length / pageSize),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      globalFilter,
      columnFilters,
      pagination: {
        pageSize,
        pageIndex: 0, // Initial page index
      },
    },
  });

  // Handle pagination changes
  const handlePageClick = (page) => setPageIndex(page);

  const handlePreviousPage = () => {
    if (pageIndex > 0) setPageIndex((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (pageIndex < totalPages - 1) setPageIndex((prev) => prev + 1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPageIndex(0);
  };

    // Pagination Range Logic
    const getPaginationRange = () => {
        const visiblePages = 5; // Show 5 page buttons at most
        const pages = [];
    
        // Add first page
        if (pageIndex > 2) {
          pages.push(0);
          if (pageIndex > 3) pages.push("...");
        }
    
        // Add pages around the current page
        for (
          let i = Math.max(pageIndex - 2, 0);
          i <= Math.min(pageIndex + 2, totalPages - 1);
          i++
        ) {
          pages.push(i);
        }
    
        // Add last page
        if (pageIndex < totalPages - 3) {
          if (pageIndex < totalPages - 4) pages.push("...");
          pages.push(totalPages - 1);
        }
    
        return pages;
      };
    
      const paginationRange = getPaginationRange();

  // Edit Handlers
  const handleEdit = (rowData) => {
    setEditRowId(rowData.communityPublicID);
    setEditRowData(rowData);
    setFocusedField(null);
  };

  const handleCancel = (row) => {
    if (row.isNewRow) {
      // Remove the new row from data
      setData((prevData) => prevData.filter((item) => item !== row));
      setNewRow(null);
    } else {
      setEditRowId(null);
     setEditRowData({});
     setFocusedField(null);
    }
  };

  const handleAddRow = async() => {
    const newRow = { 
        communityPublicID: null,
        communityId: null,
        communityName: "",
        builderName: "",
        photos: null,
        street: "",
        city: "",
        state: "",
        zip: "", 
        isNewRow: true,
    };
   setNewRow(newRow);
    setEditRowData(newRow);
    setData((prevData) => [newRow, ...prevData]); // Prepend the new row
  };

  const handleUploadClick = (propertyId) => {
    setSelectedPropertyId(propertyId); // Set the propertyId for the modal
    onOpen(); // Open the modal
  };


  const transformData = async(row) => {
    const datetime = new Date().toISOString();
    const response = await axiosInstanceES.get(`/lastlistcommunities`);
    const data =  response.data;

    let latestId = 0;
    let latestcommunityId = 0;

    if (data.length > 0) {
      const latestCommunityData = data[0];
      latestId = parseInt(latestCommunityData.communityPublicID, 10) || 0; // Handle default case and ensure integer
      latestcommunityId = parseInt(latestCommunityData.communityId, 10) || 0; // Handle default case and ensure integer
    }

    const newId = latestId + 1;
    const newCommunityId = latestcommunityId + 1;

    return {
      //location: `${frontendData.latitude},${frontendData.longitude}`,
      location: null,
      main: {
        sourceId: row.builderName || "tollbrothers",
        communityPublicID: row.communityPublicID,
        propertyStatus: "active",
        recordCreatedDate: new Date().toISOString(),
        createdRole: roleInfo,
        createdByUser: storedUserInfo,
        recordCreatedDate: datetime,
        recordType: 'community',
      },
      data: {
        propertyInfo: {
          address: {
            label: `${row.street},${row.city},${row.state},${row.zip}`,
            street: row.street,
            city: row.city,
            state: row.state,
            zip: row.zip,
            metroName: row.city,
            region: `${row.city ? row.city.toUpperCase() : ""}`,
          },
        },
        lotInfo: {
          lotSquareFeet: row.lotSquareFeet || null,
          legalDescription: row.legalDescription || null
        },
      },
      builder: { 
        builderName: row.builderName || null,
      },
      community: {
        communityId: row.communityId || null,
        communityName: row.communityName || '',
      }
    };
  };


  const savetransformData = async(row) => {
    const datetime = new Date().toISOString();
    const response = await axiosInstanceES.get(`/lastlistcommunities`);
    const data =  response.data;

    let latestId = 0;
    let latestcommunityId = 0;

    if (data.length > 0) {
      const latestCommunityData = data[0];
      latestId = parseInt(latestCommunityData.communityPublicID, 10) || 0; // Handle default case and ensure integer
      latestcommunityId = parseInt(latestCommunityData.communityId, 10) || 0; // Handle default case and ensure integer
    }

    const newId = latestId + 1;
    const newCommunityId = latestcommunityId + 1;

    return {
      //location: `${frontendData.latitude},${frontendData.longitude}`,
      location: null,
      main: {
        sourceId: row.builderName || "tollbrothers",
        communityPublicID: newId,
        propertyStatus: "active",
        recordCreatedDate: new Date().toISOString(),
        createdRole: roleInfo,
        createdByUser: storedUserInfo,
        recordCreatedDate: datetime,
        recordType: 'community',
      },
      data: {
        propertyInfo: {
          address: {
            label: `${row.street},${row.city},${row.state},${row.zip}`,
            street: row.street,
            city: row.city,
            state: row.state,
            zip: row.zip,
            metroName: row.city,
            region: `${row.city ? row.city.toUpperCase() : ""}`,
          },
        },
        lotInfo: {
          lotSquareFeet: row.lotSquareFeet || null,
          legalDescription: row.legalDescription || null
        },
      },
      builder: { 
        builderName: row.builderName || null,
      },
      community: {
        communityId: newCommunityId,
        communityName: row.communityName || '',
      }
    };
  };

 const handleSave = async (newRow) => {
  console.log("newRow", editRowData);
    if (!validateNewRow(editRowData)) {
      return; // Stop the save operation if validation fails
    }

    try {
      const transformedData = await savetransformData(editRowData);
      console.log("transformedData13121",transformedData);
      const payload = {
        role : roleInfo,
        properties: [transformedData.main.communityPublicID] 
      };

      const dynamoApiUrl = `/users/${storedUserInfo}/properties`;
      const elasticsearchApiUrl = `/addcommunity`;
  
      // Save to DynamoDB
      const dynamoResponse = await axiosInstance.post(dynamoApiUrl, payload);
  
      // Save to Elasticsearch
      const elasticsearchResponse = await axiosInstanceES.post(elasticsearchApiUrl, transformedData);
      setEditRowData(null);
      setNewRow(null);
      setEditRowId(null);
      setFocusedField(null);
      fetchData();
      // Check if both requests were successful
      if (dynamoResponse.status === 200 && elasticsearchResponse.status === 200) {
        toast({
          title: t('community-addedmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      
      } else {
        throw new Error('One of the requests failed');
      }
    } catch (error) {
      console.error('Error saving new row:', error);
      toast({
        title: 'Error',
        description: t('updatefailed'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  };

  const validateNewRow = (newRow) => {

    if (!newRow) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'No data to validate. Please fill out the form.',
      });
      return false;
    }
    console.log("newRow123", newRow);
    const requiredFields = ['communityName', 'builderName', 'street', 'city', 'state', 'zip'];
    const errors = [];
  
    // Validate if the newRow object has the required fields filled
    requiredFields.forEach(field => {
      if (!newRow[field] || newRow[field].trim() === '') {
        errors.push(field);
      }
    });
  
    if (errors.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: `Please fill in the following required fields: ${errors.join(', ')}`,
      });
      return false;
    }
  
    return true;
  };

  const handleUpdate = async (event) => {
    try {
      const transformedData = await transformData(editRowData);
      console.log("editRowData",event);
      await axiosInstanceES.put(`/updatecommunity/${event.original.communityPublicID}`, transformedData);
      await fetchData();
      setEditRowId(null);
      setFocusedField(null);
      toast({
        title: t('community-updatedmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    } catch (error) {
      console.error('Error updating row:', error);
      toast({
        title: 'Error',
        description: t('updatefailed'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  }


  const handleDelete = async (id) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure you want to delete?',
      text: 'You will not be able to recover this community!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
  
    if (isConfirmed) {
      try {
        // Check if the row is a new row (not yet saved in the database)
        const isNewRow = data === id;
  
        if (isNewRow) {
          setData(data.filter(row => row.id !== id));
          toast({
            title: t('community-deletemessage'),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        } else {
          const response = axiosInstanceES.delete(`/deletecommunity/${id}`);
  
          if (response.status === 200) {
            setData((prevRowData) => prevRowData.filter(row => row.id !== id));
            //gridOptions.api.refreshInfiniteCache();
            toast({
              title:  t('community-deletemessage'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'bottom-left',
            });
            fetchData();
          } else {
            throw new Error('Failed to delete community');
          }
        }
      } catch (error) {
        console.error('Error deleting community:', error);
        toast({
          title: 'Error',
          description:  t('updatefailed'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        })
      }
    }
  };

  return (
    <div className="bg-primary font-linksans">
    <div className="w-full">
          <div className="flex justify-end mb-4">
            <Button colorScheme="blue"  onClick={handleAddRow}>
              Add New Community
            </Button>
          </div>
      {/* Table */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
        <div className="overflow-x-auto">
          <table
           className="w-full border border-gray-300 bg-gray-50"
           style={{
             borderCollapse: "collapse",
           }}
          >
            <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="border-b bg-slate-300 border-gray-300 text-center p-2"
                style={{
                  minWidth: "150px", // Adjust this width as needed for better responsiveness
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}

                 {/* Column Filters */}
                 {header.column.getCanFilter() ? (
                    <div className="mt-1">
                      {flexRender(
                        header.column.columnDef.Filter,
                        header.getContext()
                      )}
                    </div>
                  ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className="border-b border-gray-300 p-2 text-center"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
          </table>
          </div>
          <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Button
          onClick={handlePreviousPage}
          disabled={pageIndex === 0}
          style={{ marginRight: "10px" }}
        >
           <FaChevronLeft />
        </Button>

        {paginationRange.map((page, idx) =>
          page === "..." ? (
            <span key={idx} style={{ margin: "0 5px" }}>
              ...
            </span>
          ) : (
            <Button
              key={page}
              onClick={() => handlePageClick(page)}
              style={{
                marginRight: "5px",
                backgroundColor: pageIndex === page ? "blue" : "gray",
                color: pageIndex === page ? "gray" : "black",
              }}
            >
              {page + 1}
            </Button>
          )
        )}

        <Button
          onClick={handleNextPage}
          disabled={pageIndex >= totalPages - 1}
          style={{ marginLeft: "10px" }}
        >
           <FaChevronRight />
        </Button>
      </Box>
      {/* Page Size Selector */}
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Select
          value={pageSize}
          onChange={handlePageSizeChange}
          width="auto"
          style={{ marginLeft: "10px" }}
        >
          {[100, 200, 300, 400, 500].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </Select>
      </Box>
           {/* Community Upload Modal */}
                {isOpen && (
                    <CommunityUploadModal
                    isOpen={isOpen}
                    onClose={onClose}
                    propertyId={selectedPropertyId} // Pass the selected propertyId
                    />
                )}
        </>
      )}
    </div>
    </div>
  );
};

export default CommunityTable;
