import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  fonts: {
    body: "Linik Sans, Arial, sans-serif",
    heading: "Linik Sans, Arial, sans-serif",
    // Add more font styles as needed
  },
  styles: {
    global: {
      // Define styles for active link
      "p a.chakra-link": {
        color: "black !important",
      },

      ".chakra-container p": {
        color: "black !important",
      },
      // "a.active": {
      //   backgroundColor: "gray.400",
      //   color: "black.200",
      // },
      // Define styles for inactive link
      // "a.inactive": {
      //   backgroundColor: "transparent",
      //   color: "gray.600",
      // },
      ".footerPage": {
        backgroundColor: "#e2e8f0 !important;",
        marginTop : "0px"
      },
      ".page-content": {
        backgroundColor: "#e6ebef !important;",
      },
     ".bgbadge" : {
       backgroundColor: "#ffab00 !important;",
     },
     ".contentdiv p" :{
        margin: "0.0px 0.0px 15.0px 0.0px",
        lineHeight : "1.65em",
        fontWeight : "300"
     },
     ".contentdiv p strong" :{
        fontWeight : "500"
     },
     ".contentdiv2 p" :{
        margin: "0.0px 0.0px 15.0px 0.0px",
        lineHeight : "1.65em",
        fontWeight : "300"
     },
     ".contentdiv li" :{
        margin: "0.0px 0.0px 15.0px 25px",
        lineHeight : "1.25em",
        listStyleType:"decimal",
        fontWeight: "100"
     },
     ".scroll-y-div" :{
        marginTop: "20.0px",
        lineHeight : "1.25em",
        listStyleType:"decimal",
        fontWeight: "100",
        background: "#fff",
       overflowY: "scroll",
       height: "53vh"
     }
    },
  },
  colors: {
    customBlue: "#e2e8f0",
    customOrange: {
      50: '#FFF4E0',
      100: '#FFDEB3',
      200: '#FFC680',
      300: '#FFAE4D',
      400: '#FF9626',
      500: '#FFAB00', // Main color
      600: '#DB8500',
      700: '#B75F00',
      800: '#934200',
      900: '#6F2900',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "normal", // Make all buttons bold
        borderRadius: "2px", // Set default border radius
        backgroundColor: "#fafbfc !important", // Set default background color to white
        color: "black", // Default text color
        _hover: {
          backgroundColor: "gray.300 !important", // Lighter background color on hover
          color: "black",
        },
      },
      variants: {
        customOutline: {
          color: 'black',
          border: '2px solid',
          borderColor: 'gray.200',
          backgroundColor: '#fafbfc !important',
          px: '4', // Padding X
          py: '3', // Padding Y
          _hover: {
            bg: 'gray.300 !important',
            color: 'black',
          },
        },
      },
      defaultProps: {
        variant: 'customOutline', // Set default variant to your custom button
      },
    },
  },
});

export default customTheme;
