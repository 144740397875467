
function GroupBuyerMonthlyEvent() {
    return (
      <div className="font-linksans">
        <iframe id="JotFormIFrame-243225487047156" 
        title="Prod-Monthly-Co-Buying-Club-1"
         onload="window.parent.scrollTo(0,0)"
          allowtransparency="true" 
          allow="geolocation; microphone; camera; fullscreen" 
          src="https://form.jotform.com/243225487047156" 
          frameborder="0" 
          style={{"minWidth":"100%", maxWidth: "100%", "height":"800px", "border":"none"}}
          scrolling="no" > </iframe> 
      </div>
    )
  }
  
  export default GroupBuyerMonthlyEvent