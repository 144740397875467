import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { HomeLinks as Links } from "../../constants";

const HomeLinks = ({ onClose }) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const navigate = useNavigate();

  // Filter the links based on the environment variable
  const filteredLinks = Links.filter(item => {
    if (item.label === 'New Home Buyers') {
      return process.env.REACT_APP_NEW_HOME_BUYER === 'true';
    }
    return true;
  });

  const handleCategoryClick = (index, item) => {
    // If "Login" is clicked, redirect immediately
    if (item.label === 'Login') {
      navigate(item.path);
      if (onClose) onClose();
    } else if (item.label === 'Journey Blog') {
      // Open the Journey Blog in a new tab
      window.open('https://journey.knockthedoor.com/', '_blank'); 
      if (onClose) onClose();
     } else {
      // Toggle the active category without closing the slider
      setActiveCategory(activeCategory === index ? null : index);
    }
  };

  const handleSubcategoryClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (onClose) {
      onClose();
    }
  };

  const renderLink = (sub) => {
    if (sub.path.startsWith("http")) {
      return (
        <a
          href={sub.path}
          target="_blank"
          rel="noopener noreferrer"
          className="text-center text-sm hover:bg-primary transition-all duration-300 py-2 block"
          onClick={handleSubcategoryClick}
        >
          {sub.label}
        </a>
      );
    } else if (sub.path.includes("#")) {
      return (
        <HashLink
          smooth
          to={sub.path}
          className="text-center text-sm hover:bg-primary transition-all duration-300 py-2 block"
          onClick={handleSubcategoryClick}
        >
          {sub.label}
        </HashLink>
      );
    } else {
      return (
        <Link
          to={sub.path}
          className="text-center text-sm hover:bg-primary transition-all duration-300 py-2 block"
          onClick={handleSubcategoryClick}
        >
          {sub.label}
        </Link>
      );
    }
  };

  return (
    <>
      {filteredLinks.map((item, index) => (
        <div key={index}>
          <div onClick={() => handleCategoryClick(index, item)}>
            <h4 className="text-center hover:bg-primary transition-all duration-300 py-3 cursor-pointer">
              {item.label}
            </h4>
          </div>

          {/* Render subcategories if the category is active, except for "Login" */}
          {activeCategory === index &&
            item.subcategories &&
            !["Login", "Journey Blog"].includes(item.label) && (
              <div className="ml-4">
                {item.subcategories.map((sub, subIndex) => (
                  <div key={subIndex}>
                    {renderLink(sub)}
                  </div>
                ))}
              </div>
            )}
        </div>
      ))}
    </>
  );
};

export default HomeLinks;
