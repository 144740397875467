import React from "react";
import { Box, Divider, VStack, Text,Heading, Container } from "@chakra-ui/react";
import SearchHeader from "./SearchHeader";
import Breadcrumb from "../Breadcrumb";
import '../search/styles.css';
import Footer from "../Footer";
const SearchComponent = () => {
  return (
    <div className="bg-primary font-linksans">
      <div className='container'>
       <Box >
              <Box p={2}>
                     <Breadcrumb/>
                     <Divider/>
                     <SearchHeader/>
              </Box>
              <Footer />
       </Box>
       </div>
    </div>
   
  );
};

export default SearchComponent;
