import { useEffect, useState } from 'react';
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import { Link } from 'react-router-dom';

function Companies() {
  const [complist, setCompList] = useState([]);

  useEffect(()=>{
    axiosInstanceES.get(`/companies`)
      .then(response => {
        const frags = {states:new Set(), cities: new Set()};
        console.log("responsedata", response.data);
        setCompList(response.data);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
  },[]);

  return (
    <div className='container mx-auto 2xl:py-10 xl:py-10 lg:py-14 md:py-14 py-8'>
        <div className="xl:pb-12 lg:pb-10 md:pb-8 pb-8 2xl:pb-14 font-semibold text-textColor">
          <h3 className='2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl '>
            Fortune 500 Groups
          </h3>
          <span>Start by selecting your company name and join your company group</span>
        </div>
        <div className=''>
				<div className='bg-primary px-3 overflow-auto text-nowrap 2xl:flex-nowrap xl:flex-wrap  gap-2.5 2xl:gap-0  py-3 flex justify-between rounded-lg'>
        <h4 className={'p-2 bg-white shadow-md text-textColor cursor-pointer  rounded-md'}>
          Join your company group now
				</h4>
				</div>

				<div className='py-5'>
					<div className='grid 2xl:grid-cols-5 lg:grid-cols-4 xl:grid-cols-4 md:grid-cols-3 grid-cols-2 xl:gap-y-3.5 md:gap-y-2.5 lg:gap-y-3 2xl:gap-y-4 gap-y-2 w-full h-full px-4 '>
						{complist?.map((comp, index) => (
							<div className='2xl:pb-4 pb-3' key={index}>
								<div className='w-fit'>
									<div className='relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-[rgb(27,29,31)] after:bg-[rgb(27,29,31)] before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-[rgb(0,102,204)]after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]'>
										<Link to={`/groupbuyers/get-started/${comp.companyid}?cn=${comp.company_name}`} className='text-gray-700 font-medium'>
											{comp.company_name}
										</Link>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
      
    </div>
  )
}

export default Companies