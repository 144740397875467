import React, { useEffect, useState } from 'react';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const MenuHeader = () => {
  //const [loginRole, setLoginRole] = useState("");
  const [pathFor, setPathFor] = useState("");
  const [pathname, setPathName] = useState("");
  const location = useLocation();

const menuLinks = [{
  buyer: {
    title: "Individual Buyers",
    list:[
      "home"
    ]
  }
}]
  useEffect(() => {
    let pathname = location.pathname;
    setPathName(pathname);
    pathname = pathname.split("/")[1]
    if(pathname == 'search' || pathname =='shortlisted' || pathname=='groups')
      pathname = 'buyer';
    setPathFor(pathname);
  }, [location.pathname])

  return (
    <div className="flex 2xl:flex gap-4">
               <div>
                <div className="dropdown cursor-pointer dropdown-hover">
                  <div tabIndex={0} className={pathFor ==='groupbuyers'? 'activeHeader':''}>
                    Rental Home Buyers
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu bg-white  rounded-md  z-[1] w-[250px] pb-4 shadow-xl"
                  >
                    <div className="flex">
                      <div className="w-full flex flex-col ">
                      <RouteLink to="/groupbuyers/home/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Home</h4>
                       </RouteLink>

                       <RouteLink to="/groupbuyers/get-started/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Getting Started</h4>
                       </RouteLink>

                       <Link to="/groupbuyers/home#why-choose-us" tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Why Choose US</h4>
                       </Link>

                       <Link to="/groupbuyers/home#still-why" tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Still Why</h4>
                       </Link>
                       
                       <Link to="/groupbuyers/home#join-co-buying-club" tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Join Co-Buying Club</h4>
                       </Link>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
             {process.env.REACT_APP_NEW_HOME_BUYER === 'true' && (
              <div>
                <div className="dropdown cursor-pointer dropdown-hover">
                  <div tabIndex={0} className={pathFor ==='buyer'? 'activeHeader':''}>
                   New Home Buyers
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu bg-white  rounded-md  z-[1] w-[250px] pb-4 shadow-xl"
                  >
                    <div className="flex">
                      <div className="w-full flex flex-col ">
                      <RouteLink to="/buyer/home/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4 className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Home</h4>
                       </RouteLink>

                       <RouteLink to="/buyer/get-started/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Getting Started</h4>
                       </RouteLink>

                       <Link to="/buyer/home#why-choose-us" tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Why Choose US</h4>
                       </Link>

                       <RouteLink to="/search/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Search</h4>
                       </RouteLink>

                       <RouteLink to="/shortlisted/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Shortlists</h4>
                       </RouteLink>


                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              )}
              <div>
                <div className="dropdown cursor-pointer dropdown-hover">
                  <div tabIndex={2} className={pathFor ==='builder'? 'activeHeader':''}>
                   Builders
                  </div>
                  <ul
                    tabIndex={2}
                    className="dropdown-content menu bg-white  rounded-md  z-[1] w-[250px] pb-4 shadow-xl"
                  >
                   <div className="flex">
                      <div className="w-full flex flex-col ">
                      <RouteLink to="/builder/home/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Home</h4>
                       </RouteLink>

                       <RouteLink to="/builder/get-started/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Getting Started</h4>
                       </RouteLink>

                       <Link to="/builder/home#why-choose-us" tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Why Choose US</h4>
                       </Link>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>

              <div>
                <div className="dropdown cursor-pointer dropdown-hover">
                  <div tabIndex={3} className={pathFor ==='agent'? 'activeHeader':''}>
                    Agents
                  </div>
                  <ul
                    tabIndex={3}
                    className="dropdown-content menu bg-white  rounded-md  z-[1] w-[250px] pb-4 shadow-xl"
                  >
                    <div className="flex">
                      <div className="w-full flex flex-col ">
                      <RouteLink to="/agent/home/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Home</h4>
                       </RouteLink>

                       <RouteLink to="/agent/get-started/" onClick={() => window.scrollTo(0, 0)} tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Getting Started</h4>
                       </RouteLink>

                       <Link to="/agent/home#why-choose-us" tabIndex={0} className="">
                        <h4  className="text-paragraphColor hover:bg-base-200 transition-colors px-1 py-2 duration-200" >Why Choose US</h4>
                       </Link>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>

              <div>
                  <a
                    href="https://journey.knockthedoor.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hidden sm:block cursor-pointer hover:bg-base-200 transition-colors duration-200"
                  >
                    Journey Blog
                  </a>
                </div>

            </div>
  );
};

export default MenuHeader;
