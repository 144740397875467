import howitsworksImg from "../../../assets/images/howitworks-rental-buyers-1b.webp";

function HomeV3Accordion3() {
  return (
    <div className="container mx-auto 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8">
      <div>
        <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl font-semibold text-center text-textColor pb-5">
        How it works!
        </h3>
        <div className="flex justify-center items-center">
        <div className="w-full max-w-2xl h-auto font-linksans">
          <img
            src={howitsworksImg}
            alt="How its Work Background"
            className="w-full h-full object-cover"
          />
        </div>
    </div>
    </div>
    </div>
  );
}

export default HomeV3Accordion3;
