import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import axios from "axios";
import axiosInstance from "../../utils/axio-instance";


const ProfileModal = ({ userId, isOpen, onClose }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (isOpen && userId) {
      fetchData();
    }
  }, [isOpen, userId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch profile data
      const profileResponse = await axiosInstance.get(`/users/${userId}/profile/`);
      setUserProfile(profileResponse.data);

      // Fetch preferences data
      const preferencesResponse = await axiosInstance.get(`/users/${userId}/preferences/`);
      setUserPreferences(preferencesResponse.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Profile Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <p>Loading...</p>
          ) : userProfile ? (
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td>Full Name</Td>
                  <Td>
                    {userProfile.first_name || ""} {userProfile.last_name || ""}
                  </Td>
                </Tr>
                <Tr>
                  <Td>Email</Td>
                  <Td>{userProfile.email}</Td>
                </Tr>
                <Tr>
                  <Td>Phone Number</Td>
                  <Td>{userProfile.mobile}</Td>
                </Tr>
                <Tr>
                  <Td>Alternate Number</Td>
                  <Td>{userProfile.phone_2}</Td>
                </Tr>
                <Tr>
                  <Td>Address</Td>
                  <Td>{userProfile.primary_address}</Td>
                </Tr>
              </Tbody>
            </Table>
          ) : (
            <p>No Profile Data</p>
          )}

          {userPreferences && (
            <>
              <h3 className="font-bold mt-4 text-md">User Preferences</h3>
              <Table variant="simple" mt={4}>
                <Tbody>
                  <Tr>
                    <Td>Preferred Location</Td>
                    <Td>{userPreferences.preferredLocation}</Td>
                  </Tr>
                  <Tr>
                    <Td>Preferred Property Types</Td>
                    <Td>{userPreferences.preferredPropertyTypes.join(", ")}</Td>
                  </Tr>
                  <Tr>
                    <Td>Preferred Price Range</Td>
                    <Td>
                      {userPreferences.preferredPriceRange.join(" - ")}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProfileModal;
