import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  VStack,
  Avatar,
  Heading,
  Text,
  HStack,
  useToast,
  Container,
  Spinner,
  Stack
} from '@chakra-ui/react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import { propTypeLabels } from '../../constants';
import { formatAddress, formatPrice } from '../../utils/format';
import { Link } from 'react-router-dom';
import { useAuth } from '../../components/user/AuthProvider';
import { useTranslation } from 'react-i18next';

const ReferralRespond = () => {
  const toast = useToast();
  const { token } = useParams();
  const { user, setUser } = useAuth();
  const { t, i18n  } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [referralDetails, setReferralDetails] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem('userid');
    async function loadInitData(){
        const agreementAcepted = await agreementCheck(userId);
        if(!agreementAcepted){
            navigate(`/referral-agreement/${token}`);
            return;
        }
        if (token) {
        // Fetch the invitation details using the token
        axiosInstance.get(`/referral/get/${token}`)
            .then((response) => {
            setReferralDetails(response.data.data);
            setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            toast({
                title: 'Error',
                description: "Token Expired",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'bottom-left',
            });

            setError('Invalid token or token has been expired');
            setLoading(false);
            });
        } else {
        setError('Invalid token.');
        setLoading(false);
        }
    }
    if(userId){
        loadInitData(userId);
    }
  }, [user, location, navigate]);

  const agreementCheck = async (agentId)=>{
    return axiosInstance.get(`/users/${agentId}/agent-license`)
        .then((response) => {
            console.log("acceptedAgreement:", response.data.acceptedAgreement);
            return response.data.acceptedAgreement;
        })
        .catch((err) => {
          toast({
            title: 'Error',
            description: "Token Expired",
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
          setError('Failed to fetch Referral details.');
          setLoading(false);
          return false;
        });
  }

  useEffect(() => {
    if (referralDetails?.property_id) {
      axiosInstanceES.get(`/properties/${referralDetails?.property_id}`)
        .then((response) => {
            if(response.data.length>0){
                setPropertyDetails(response.data[0]); // Store property details
            }           
        })
        .catch((e) => {
            console.log(e)
          //setError('Failed to fetch inviter details.');
        });
    }
  }, [referralDetails]);

  const updateReferralStatus = (status)=>{
    axiosInstance.put(`/referral/respond-invite/${referralDetails.referral_id}`, {
        "token": token,
        "status": status
    })
    .then((response) => {
        navigate("/agent/referrals/");
    if(status == 'accepted'){
      toast({
        title: t('agent-referral-accepted-title'),
        description: t('agent-referral-accepted-msg'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }else{
        toast({
            title: t('agent-referral-rejected-title'),
            description: t('agent-referral-rejected-msg'),
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          }); 
    }
    })
    .catch((error) => {
      console.error('Error details:', error.response);
      const errorMessage = error.response?.data?.message || 'Failed to accept/reject request.';
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    });
  }

  const handleAccept = () => {
    updateReferralStatus('accepted');
  };
  
  const handleReject = () => {
    updateReferralStatus('rejected');
  };

  if (loading) {
    return (
      <Container centerContent mt={10}>
        <Spinner size="xl" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container centerContent mt={10} className='min-h-svh'>
        <Text as="h2" fontSize={"lg"} color="red.500">Error</Text>
        <Text as="h2" color="red.500">{error}</Text>
      </Container>
    );
  }

  return (
    <div className="min-h-svh bg-primary">
    <div className="flex items-center justify-center py-5 px-4 sm:px-6 lg:px-8 font-linksans">
     <div className="max-w-lg w-full bg-white border border-gray-200 rounded-lg shadow-lg p-6">
    <div className="space-y-4">
      <h3 className="text-2xl font-bold text-gray-800 sm:text-3xl text-center">
        {t('accept-referral')}
      </h3>
      {referralDetails != null ? (
        <>
          {/* User details */}
          <p className="text-gray-600 text-base">
            {t('referral-buyer-note', { name: referralDetails.lastName })}
          </p>
          <div className="space-y-2">
            <p className="text-gray-500 font-semibold">{t('buyer-preference')}:</p>
            <p className="text-gray-700">{t('location')}: {referralDetails.preferredLocation}</p>
            <p className="text-gray-700">{t('property-type')}: {propTypeLabels[referralDetails.preferredPropertyTypes]}</p>
            {referralDetails.preferredPriceRange && referralDetails.preferredPriceRange.length > 1 && (
              <p className="text-gray-700">
                {t('price-range')}: ${formatPrice(referralDetails.preferredPriceRange[0])} - ${formatPrice(referralDetails.preferredPriceRange[1])}
              </p>
            )}
            {propertyDetails && (
              <>
                <p className="text-gray-500 font-semibold">{t('property-details')}:</p>
                <p className="text-gray-700">{propertyDetails.modelName}</p>
                <p className="text-gray-700">{formatAddress(propertyDetails.address)}</p>
                {/* Uncomment if you need a property link */}
                {/* <a 
                  className="inline-block mt-2 text-blue-600 hover:bg-blue-100 transition-colors rounded-lg px-3 py-2 font-medium"
                  href={`/home/en/${propertyDetails.state}/${propertyDetails.city}/${propertyDetails.id}/${propertyDetails.communityname}/${propertyDetails.property_type}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('view-details')}
                </a> */}
              </>
            )}
          </div>
          {/* Buttons for Accept and Reject */}
          <div className="flex space-x-2 mt-4">
            <button
              className="w-full py-2.5 text-white bg-green-600 hover:bg-green-700 transition-all duration-300 font-semibold rounded-lg"
              onClick={handleAccept}
            >
              {t('accept')}
            </button>
            <button
              className="w-full py-2.5 text-white bg-red-600 hover:bg-red-700 transition-all duration-300 font-semibold rounded-lg"
              onClick={handleReject}
            >
              {t('reject')}
            </button>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  </div>
</div>
</div>
  );
};

export default ReferralRespond;
