import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_DYNAMO_API_URL
});

export const axiosInstanceES = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export const axiosInstanceIMG = axios.create({
    baseURL: process.env.REACT_APP_IMAGE_API_URL
});
    
export const updateToken = (token)=>{
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
    axiosInstanceES.defaults.headers['Authorization'] = `Bearer ${token}`;
    axiosInstanceIMG.defaults.headers['Authorization'] = `Bearer ${token}`;
}

export default axiosInstance;
