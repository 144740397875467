import { IoLogoInstagram } from "react-icons/io";
import { ImFacebook } from "react-icons/im";
import { RiTwitterXFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


function Footer() {
  return (
    <div className="bg-borderColor font-linksans">
      <div className=" container mx-auto 2xl:py-10 py-8 md:py-10 xl:py-10 lg:py-10">
        <div className="grid 2xl:grid-cols-5 grid-cols-2 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-5 2xl:gap-0 relative justify-between">
          {/* row */}
          <div>
            <h4 className="text-textColor hover:text-gray-600 md:text-base lg:text-base xl:text-base text-sm 2xl:text-base lg:pb-3 xl:pb-4 2xl:pb-4 pb-2.5 font-semibold">
              Rental Home Buyers
            </h4>
            <div className="flex flex-col gap-1.5 xl:gap-2.5 lg:gap-2.5 2xl:gap-3">
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <Link to={'/groupbuyers/home/'} onClick={() => window.scrollTo(0, 0)}>Home</Link>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <HashLink to={'/groupbuyers/home#why-choose-us'} onClick={() => window.scrollTo(0, 0)}>Why Choose Us</HashLink>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <HashLink to={'/groupbuyers/home#still-why'} onClick={() => window.scrollTo(0, 0)}>Still Why</HashLink>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <HashLink to={'/groupbuyers/home#join-co-buying-club'} onClick={() => window.scrollTo(0, 0)}>Join Co-Buying Club</HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {process.env.REACT_APP_NEW_HOME_BUYER === 'true' && (  
          <div>
            <h4 className="text-textColor hover:text-gray-600 md:text-base lg:text-base xl:text-base text-sm 2xl:text-base lg:pb-3 xl:pb-4 2xl:pb-4 pb-2.5 font-semibold">
              New Home Buyers
            </h4>
            <div className="flex flex-col xl:gap-2.5 lg:gap-2.5 gap-1.5 2xl:gap-3">
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
                <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <Link to={'/buyer/home/'} onClick={() => window.scrollTo(0, 0)}>Home</Link>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600 ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <Link to={'/shortlisted/'} onClick={() => window.scrollTo(0, 0)}>Shortlists</Link>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <HashLink to={'/buyer/home#why-choose-us'} onClick={() => window.scrollTo(0, 0)}>Why Choose Us</HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
           )}
          {/* row */}
          <div>
            <h4 className="text-textColor hover:text-gray-600 md:text-base lg:text-base xl:text-base text-sm 2xl:text-base lg:pb-3 xl:pb-4 2xl:pb-4 pb-2.5 font-semibold">
              Builders
            </h4>
            <div className="flex flex-col gap-1.5 xl:gap-2.5 lg:gap-2.5 2xl:gap-3">
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <Link to={'/builder/home/'} onClick={() => window.scrollTo(0, 0)}>Home</Link>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <HashLink to={'/builder/home#why-choose-us'} onClick={() => window.scrollTo(0, 0)}>Why Choose Us</HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4 className="text-textColor hover:text-gray-600 md:text-base lg:text-base xl:text-base text-sm 2xl:text-base lg:pb-3 xl:pb-4 2xl:pb-4 pb-2.5 font-semibold">
              Agent
            </h4>
            <div className="flex flex-col gap-1.5 xl:gap-2.5 lg:gap-2.5 2xl:gap-3">
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <Link to={'/agent/home/'} onClick={() => window.scrollTo(0, 0)}>Home</Link>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <HashLink to={'/agent/home#why-choose-us'} onClick={() => window.scrollTo(0, 0)}>Why Choose Us</HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4 className="text-textColor hover:text-gray-600 md:text-base lg:text-base xl:text-base text-sm 2xl:text-base lg:pb-3 xl:pb-4 2xl:pb-4 pb-2.5 font-semibold">
              Company
            </h4>
            <div className="flex flex-col gap-1.5 xl:gap-2.5 lg:gap-2.5 2xl:gap-3">
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <Link to={'/about-us/'} onClick={() => window.scrollTo(0, 0)}>About us</Link>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <Link to="https://support.knockthedoor.com">Support</Link>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <Link to="https://support.knockthedoor.com">Contact Us</Link>
                  </div>
                </div>
              </div>
              <div className="text-textColor 2xl:text-base text-sm md:text-base lg:text-base xl:text-base hover:text-gray-600  ">
              <div className="w-fit">
                  <div className="relative text-textColor cursor-pointer transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-300 before:absolute before:bg-gray-500 before:origin-center before:h-[1px] before:w-0 hover:before:w-[50%] before:bottom-0 before:left-[50%] after:transition-[width] after:ease-in-out after:duration-300 after:absolute after:bg-gray-500 after:origin-center after:h-[1px] after:w-0 hover:after:w-[50%] after:bottom-0 after:right-[50%]">
                    <Link to="https://journey.knockthedoor.com/" target="_blank">Journey Blog</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4 className="2xl:py-10 md:py-10 text-sm md:text-base lg:text-base xl:text-base 2xl:text-base py-8 text-paragraphColor">
        <p className="text-13px">We gather data from many sources, agents, builders, and other sources, therefore, this website data might have inaccuracies. We do not assume any accuracy of property data and other data presented on this website. It is your responsibility to independently verify the information on this website.&nbsp;</p>
        <p className="text-13px">The information provided on this website is for general informational and educational purposes only and must NOT be construed as legal, financial, investment or any other expert advice. Real estate purchasing or investing involves many risks; any content presentations, pages, blog posts must not be construed as expert advice, results vary based on many many internal or external factors and variables.</p>
          <Link to={"/disclosures/"} onClick={() => window.scrollTo(0, 0)}>
            <h4 className="text-13px hover:underline">.... Read more</h4>
          </Link>
        </h4>

        <div className="flex justify-between lg:flex-row flex-col xl:flex-row 2xl:flex-row gap-8 2xl:gap-0 items-center">
          <div className="flex text-sm md:text-base lg:text-base xl:text-base 2xl:text-base md:flex-row md:flex-wrap  md:gap-6 items-center flex-col 2xl:flex-row gap-4 2xl:gap-14 text-paragraphColor">
            <h4>© 2024 Knock The Door Inc</h4>
            
            
            <Link target="_blank"  className="hover:underline" to="/disclosures">
            Disclosure
            </Link>
            <Link target="_blank" className="hover:underline" to="/privacy/">
              Privacy
            </Link>
            <Link target="_blank" className="hover:underline" to="/terms/">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
