import { Link } from "react-router-dom"


function MonthlyEvent() {
  return (
    <div id="join-co-buying-club" className="container mx-auto 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8">
        <div className="bg-primary px-12 py-12 flex flex-col justify-center items-center rounded-lg ">
            <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl text-center font-semibold text-textColor pb-5">
            Join Co-Buying Monthly Event Club</h3>
            <p className="text-paragraphColor pb-8 text-center w-full">
            Our monthly event club members join in a monthly video call to discuss together on various passive real estate rental topics such as markets,
            communities, trends. We will send email invitations once you join the club. You simply join, learn, debate and participate in 
            Co-Buying opportunities if you like those opportunities.
            </p>
            <div>
              <Link 
                to="/groupbuyers/co-buying-club/" 
                className="text-[#060605] bg-white hover:bg-btnbg transition-all duration-300 btnfontpage text-center text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-3 rounded-lg border border-borderColor text-center block w-full sm:w-auto"
              >
                 Join the Co-Buying Club now!
              </Link>
            </div>
        </div>
    </div>
  )
}

export default MonthlyEvent