import React, { useState } from 'react';
import { Image, Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageScroll = ({ images, maxThumbnails = 5, onThumbnailClick }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleThumbnailClick = (index) => {
    if (onThumbnailClick) {
      onThumbnailClick(index);
    }
    setSelectedItem(index);
  };

  const getImageUrl = (key) => `${process.env.REACT_APP_IMAGE_API_URL}/images/download/${key}`;

  return (
    <Box>
      {/* Tabs for categories */}
      <Tabs isLazy variant="enclosed">
        <TabList>
          {Object.keys(images).map((category, index) => (
            <Tab key={category}>{category.charAt(0).toUpperCase() + category.slice(1)}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {Object.entries(images).map(([category, categoryImages], index) => (
            <TabPanel key={category}>
              <Box mt="3px">
              <Carousel
                showThumbs={true}
                infiniteLoop={true}
                selectedItem={selectedItem}
                showIndicators={false}
                showStatus={false}
                showArrows={true}
                emulateTouch={true}
                thumbWidth={150}
                dynamicHeight={false}
                
              >
                {categoryImages.map((img, imgIndex) => (
                  <div key={imgIndex} style={{ width: '100%', borderRadius: '30px', overflow: 'hidden' }}>
                    <img
                       src={getImageUrl(img)}
                      alt={`Image ${category} ${imgIndex + 1}`}
                      className="carousel-image"
                    />
                  </div>
                ))}
              </Carousel>
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ImageScroll;
