import React from 'react';
import {
  Box,
  Heading,
  Text,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
  ListIcon,
  Link,
  Container
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';

const DisclosuresPage = () => {
  return (
    <div className="bg-primary  font-linksans min-h-svh">
      <div className="container px-12 py-3">
        <div className="flex-col items-center flex justify-center">
        <Box mx="auto" p={5} className="contentdiv2">
        <Heading as="h2" size="lg" mb={5}>
             Disclaimer
          </Heading>
        <p>We gather data from many sources, agents, builders, and other sources, therefore, this website data might have inaccuracies. We do not assume any accuracy of property data and other data presented on this website. It is your responsibility to independently verify the information on this website.&nbsp;</p>
        <p>The information provided on this website is for general informational and educational purposes only and must NOT be construed as legal, financial, investment or any other expert advice. Real estate purchasing or investing involves many risks; any content, presentations, pages, blog posts must not be construed as expert advice, results vary based on many many internal or external factors and variables. Always consult a licensed expert, real estate professional and/or financial advisor, experts such as attorneys about your real estate and investment decisions.</p>
        <p>The services are provided on an as-is and as-available basis. You agree that your use of the services will be at your sole risk. We make no warranties or representations about the accuracy or completeness of the services' content or the content of any websites or mobile applications linked to the services and we will assume no liability or responsibility for any (1) errors, mistakes, or inaccuracies of content and materials, (2) personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the services, (3) any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein, (4) any interruption or cessation of transmission to or from the services, (5) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the services by any third party, and/or (6) any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted, or otherwise made available via the services. We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the services, any hyperlinked website, or any website or mobile application featured in any banner or other advertising, and we will not be a party to or in any way be responsible for monitoring any transaction between you and any third-party providers of products or services. as with the purchase of a product or service through any medium or in any environment, you should use your best judgment and exercise caution where appropriate.</p>
        <p>Knock The Door’s founder is a real estate agent who would be part of any real estate transaction based on the transaction requirements</p>
        </Box>
    </div>
    </div>
    </div>
  );
};

export default DisclosuresPage;
