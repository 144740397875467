
function GroupBuyerUnSubscribe() {
    return (
      <div className="font-linksans">
        <iframe
      id="JotFormIFrame-243225067852154"
      title="Prod-Unsubscribe-Email-and-SMS-1"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allow="geolocation; microphone; camera; fullscreen"
      src="https://form.jotform.com/243225067852154"
      frameborder="0"
      style={{"minWidth":"100%", maxWidth: "100%", "height":"800px", "border":"none"}}
      scrolling="no"
    >
    </iframe>
      </div>
    )
  }
  
  export default GroupBuyerUnSubscribe