import React, { useState, useEffect } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Container,ChakraProvider } from '@chakra-ui/react';
import PropertyListing from '../tenstack/PropertyTable';
//import CommunityListing from './CommunityListing';
import CommunityListing from '../tenstack/CommunityTable';

const MainListing = () => {
  return (
    <div className="bg-primary font-linksans min-h-screen">
      <div className="w-full px-5 py-12">
        <Box>
          <Tabs variant="enclosed" style={{borderBottom:"1px solid #c4c8d8"}}>
            <TabList >
              <Tab style={{borderRadius:"8px 8px 0 0"}} 
               width={250}        
                _selected={{
                  color: "black",                  
                  fontWeight: "normal",
                  fontSize: "lg", 
                  borderRadius:"8px 8px 0 0",                 
                  backgroundColor: "#e6ebef",
                  border:"1px solid #c4c8d8",
                  borderBottomWidth: "0px"
                }}
              >
                Communities
              </Tab>
              <Tab style={{borderRadius:"8px 8px 0 0"}}   
              width={250}     
                _selected={{
                  color: "black",
                  fontWeight: "normal",
                  fontSize: "lg",                    
                  backgroundColor: "#e6ebef",
                  border:"1px solid #c4c8d8",
                  borderBottomWidth: "0px"
                }}
              >
                Properties
              </Tab>
            </TabList>
            <TabPanels className="mt-4">
              <TabPanel>
                <CommunityListing />
              </TabPanel>
              <TabPanel>
                <PropertyListing />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </div>
    </div>
  );
};

export default MainListing;
