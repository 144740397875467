import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 

const DynamicBreadcrumb = (props) => {
  const { PropertyInfo } = props;
  const location = useLocation();

  const { t, i18n  } = useTranslation();

  const showCompareLink = location.pathname.includes('/shortlisted');

  const showPropDetails = location.pathname.includes('/propertydetails');

  const showSearchLink = location.pathname.includes('/search');

  const showPropertyListingLink = location.pathname.includes('/property-listing');

  const showPropertiesLink = location.pathname.includes('/properties');

  const showUserListingLink = location.pathname.includes('/user_management');


  const breadcrumbs = [
    { label: t('home'), href: '/' },
    ...(showCompareLink ? [{ label: t('compare'), href: '/shortlisted' }] : []),
    ...(showPropDetails ? [{ label: t('properties'), href: '/propertydetails' }] : []),
    ...(showPropDetails ? [{ label: t('Community'), href: '/community' }] : []),
    ...(showSearchLink ? [{ label: t('search'), href: '/search' }] : []),
    ...(showPropertyListingLink ? [{ label: t('propertylisiting'), href: '/property-listing' }] : []),
    ...(showUserListingLink ? [{ label: t('userlisiting'), href: '/user_management' }] : []),
    ...(showPropertiesLink ? [{ label: t('properties'), href: '/properties' }] : []),
    ...(PropertyInfo?.address ? [{ label: PropertyInfo.address, href: '#' }] : []),
    ...(PropertyInfo?.communityName ? [{ label: PropertyInfo.communityName, href: '#' }] : []),
  ];



  return (
    <div className="bg-primary font-linksans">
      <div className='container'>
    <Box width="100%" position="relative" mt={3} mb={4}>
      <Breadcrumb color="gray.500" separator={<ChevronRightIcon color="gray.400" />}>
        {breadcrumbs.map((item, index) => (
          <BreadcrumbItem key={index} isCurrentPage={item.isCurrentPage}>
            <BreadcrumbLink href={item.href}>{item.label}</BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Box>
    </div>
    </div>
  );
};

export default DynamicBreadcrumb;
