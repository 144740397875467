import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
} from "@chakra-ui/react";

import { referralStatus} from '../../constants';
import DateDisplay, {formatDateHumanReadable} from '../../utils/DateDisplay';



const ITEMS_PER_PAGE = 5; // Number of rows per page

const ReferralHistoryModal = ({ isOpen, onClose, historyData }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages
  const totalPages = Math.ceil(historyData.length / ITEMS_PER_PAGE);

  // Paginated data
  const paginatedData = historyData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const getStatusLabel = (value) => {
    const status = referralStatus.find((item) => item.value === value);
    return status ? status.label : value; // Fallback to value if no match is found
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Referral History</ModalHeader>
        <ModalBody>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Timestamp</Th>
                <Th>Status</Th>
                <Th>Note</Th>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((item) => (
                  <Tr key={item.event_id}>
                    <Td><DateDisplay date={item.created_time} /></Td>
                    <Td>{ ['no-response'].includes(item.referral_status) ? '' : getStatusLabel(item.referral_status)}</Td>
                    <Td>{item.note || "N/A"}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={3} style={{ textAlign: "center" }}>
                    No history available.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          {/* Pagination Controls */}
          <Flex justifyContent="space-between" mt={4}>
            <Button
              onClick={handlePreviousPage}
              isDisabled={currentPage === 1}
              size="sm"
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              onClick={handleNextPage}
              isDisabled={currentPage === totalPages}
              size="sm"
            >
              Next
            </Button>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="blue">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReferralHistoryModal;
