import PropTypes from 'prop-types';

function CommonStatusButton({ color, text }) {
    // Default colors
    let textColor = 'text-gray-500'; // Fallback color
    let bgColor = 'bg-gray-200';      // Fallback background color
    let iconColor = 'bg-gray-500';    // Fallback icon color

    // Define colors based on the `color` prop
    switch (color) {
        case 'green':
            bgColor = 'bg-[#BBF7D0]';
            textColor = 'text-[#16A34A]';
            iconColor = 'bg-[#16A34A]';
            break;
        case 'red':
            bgColor = 'bg-[#CD4D4F33]';
            textColor = 'text-[#EA5353]';
            iconColor = 'bg-[#EA5353]';
            break;
        case 'yellow':
            bgColor = 'bg-[#FFAB0033]';
            textColor = 'text-[#FFAB00]';
            iconColor = 'bg-[#FFAB00]';
            break;
        default:
            // You can add default case handling if needed
            break;
    }

    return (
        <div className={`flex items-center justify-center w-fit gap-1.5 px-3 py-1 rounded-full ${bgColor}`}>
            <div className={`w-2.5 h-2.5 rounded-full ${iconColor}`}></div>
            <button className={`font-medium text-sm ${textColor}`}>
                {text}
            </button>
        </div>
    );
}

// Define prop types
CommonStatusButton.propTypes = {
    color: PropTypes.oneOf(['green', 'red', 'yellow']).isRequired,
    text: PropTypes.string.isRequired,
};

export default CommonStatusButton;
