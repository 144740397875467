import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Input, Button, Flex, FormControl, FormErrorMessage, useToast, Box, Text, Badge } from '@chakra-ui/react'; // Chakra UI components
import axios from 'axios';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next'; 
import { Link } from "react-router-dom";
import { useAuth } from '../user/AuthProvider';
import axiosInstance,{updateToken} from '../../utils/axio-instance';


const ProfileEdit = () => {
  const { t} = useTranslation();
  const profileId = localStorage.getItem('userid');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [primaryAddress, setPrimaryAddress] = useState('');

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { user, setUser } = useAuth();

  const [userInfo, setUserInfo] = useState(null);
  const [profileInfo, setProfileInfo] = useState(null);


  // Chakra UI Toast hook
  const toast = useToast()

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userid');
    setUserInfo(storedUserInfo);
    if(user && user ==null){
      loadUser(storedUserInfo);
    }else{
      setProfileInfo(user);
    }
  }, [localStorage.getItem('userid')]);

const loadUser = (userId)=>{
 console.log("Load user info from local storage");
    updateToken(localStorage.getItem('token'));
    // Make your profile API call here
    axiosInstance.get(`/users/${userId}/profile/`)
    .then(response => {
      // Handle profile API response
      const storedProfileInfo =  response.data;
      setProfileInfo(storedProfileInfo);
      setUser({
        ...user, ...storedProfileInfo
      });
    })
    .catch(error => {
      // Handle profile API error
      console.error('Profile API error:', error);
    });
}

  // useEffect to set the state when profileInfo changes
  useEffect(() => {
    if (profileInfo) {
      setFirstName(profileInfo.first_name);
      setLastName(profileInfo.last_name);
      setEmail(profileInfo.email);
      setMobileNumber(profileInfo.mobile || '');
      setPhoneNumber(profileInfo.phone_2 || '');
      setPrimaryAddress(profileInfo.primary_address || '');
    }
  }, [profileInfo]);

  const validate = () => {
    let formErrors = {};
    if (!firstName) formErrors.firstName = 'First name is required';
    if (!lastName) formErrors.lastName = 'Last name is required';
    if (!primaryAddress || primaryAddress.length < 10) formErrors.primaryAddress = 'Address must be at least 10 characters long';
    if (!email || !/\S+@\S+\.\S+/.test(email)) formErrors.email = 'A valid email is required';
    if (!mobileNumber || !/^\+\d+/.test(mobileNumber)) formErrors.mobileNumber = 'A valid phone number format is required (+[country code][number])';
    if (!phoneNumber || !/^\+\d+/.test(phoneNumber)) formErrors.phoneNumber = 'A valid phone number format is required (+[country code][number])';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    if (!validate()) {
      setIsSubmitting(false);
      return;
    }

    const updatedProfile = {
      first_name: firstName,
      last_name: lastName,
      mobile: mobileNumber,
      phone_2: phoneNumber,
      primary_address: primaryAddress,
    };

    axios.put(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${profileId}/profile/`, updatedProfile, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        setSuccessMessage(t('saved'));
        toast({
          title: t('profile-successmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      })
      .catch((error) => {
        let errorMessage = 'Failed to update profile. Please try again later.';
        if (error.response && error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        setErrorMessage(t('failed'));

        toast({
          title: 'Error',
          description: t('profile-errormessage'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-left',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [successMessage, errorMessage]);

  return (
    <div className="bg-primary  font-linksans">
      <div className="container px-5 py-12">
        <div className="flex-col items-center flex justify-center">
        <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
          Update Information
        </h3>

      <div className="w-full flex justify-center">
      <form action="" className="w-[500px] max-w-xl flex flex-col gap-3">
            <div className="flex flex-col gap-2">
                <label className="text-sm text-paragraphColor" htmlFor="first-name">First Name</label>
                <input className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" type="text" name="first-name" id="first-name" value ={firstName} onChange={(e) => setFirstName(e.target.value)} />
            {errors.firstName && <span className="text-sm text-red-400">{errors.firstName}</span>}

            </div>
            <div className="flex flex-col gap-2">
                <label className="text-sm text-paragraphColor" htmlFor="last-name">Last Name</label>
                <input className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" type="text" name="last-name" id="last-name" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                {errors.lastName && <span className="text-sm text-red-400">{errors.lastName}</span>}
            </div>
            <div className="flex flex-col gap-2">
                <label className="text-sm text-paragraphColor" htmlFor="phone-number">Phone Number</label>
                <input placeholder="Enter this format +[country code][number]" className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" type="tel" name="phone-number" id="phone-number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)}/>
                {errors.mobileNumber && <span className="text-sm text-red-400">{errors.mobileNumber}</span>}
            </div>
            <div className="flex flex-col gap-2">
                <label className="text-sm text-paragraphColor" htmlFor="home-phone-number">Home Phone Number</label>
                <input placeholder="Enter this format +[country code][number]" className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" type="tel" name="home-phone-number" id="home-phone-number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                {errors.phoneNumber && <span className="text-sm text-red-400">{errors.phoneNumber}</span>}
            </div>
            <div className="flex flex-col gap-2">
                <label className="text-sm text-paragraphColor" htmlFor="email">Email Id</label>
                <input placeholder="example@gamil.com" className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" type="email" name="email" id="email" value={email} readOnly/>
                {errors.email && <span className="text-sm text-red-400">{errors.email}</span>}
            </div>
            <div className="flex flex-col gap-2">
                <label className="text-sm text-paragraphColor" htmlFor="address">Address</label>
                <input placeholder="enter 10 characters length Address " className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" type="address" name="address" id="address" value={primaryAddress} onChange={(e) => setPrimaryAddress(e.target.value)}/>
                {errors.primaryAddress && <span className="text-sm text-red-400">{errors.primaryAddress}</span>}
            </div>
            <div className="flex  gap-8 items-center pt-6">
                <div className="flex-1"><button className="w-full border-gray-300 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-gray-600  border">Cancel</button></div>
                <div className="flex-1"><button type="submit" className="w-full text-sm 2xl:text-base lg:text-base xl:text-base md:text-sm 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-paragraphColor bg-white  border" onClick={handleSubmit}>Update Profile</button></div>
            </div>
        </form>
      </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
