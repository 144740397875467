import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Input, Button, Flex, FormControl, FormErrorMessage, FormLabel, useToast, Select, NumberInput, NumberInputField, Badge } from '@chakra-ui/react'; // Chakra UI components
import axiosInstance from '../../utils/axio-instance';
import { propertyTypeOptions } from '../../constants';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next'; 


const MyPreference = () => {
  const profileId = localStorage.getItem('userid');
  const [location, setLocation] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [priceMin, setPriceMin] = useState('');
  const [priceMax, setPriceMax] = useState('');

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t} = useTranslation();
  const toast = useToast();

  useEffect(() => {
    // Fetch initial preferences
    const fetchPreferences = async () => {
      try {
        const response = await axiosInstance.get(`/users/${profileId}/preferences`);
        
        const { preferredLocation = '', preferredPropertyTypes = [], preferredPriceRange = [] } = response.data || {};

        setLocation(preferredLocation || '');
        setPropertyType(preferredPropertyTypes.length > 0 ? preferredPropertyTypes[0] : '');
        setPriceMin(preferredPriceRange.length > 0 ? preferredPriceRange[0] : '');
        setPriceMax(preferredPriceRange.length > 1 ? preferredPriceRange[1] : '');
      } catch (error) {
        console.error("Error fetching preferences:", error);
      }
    };
    
    fetchPreferences();
  }, [profileId]);

  const validate = () => {
    let formErrors = {};
    if (!location) formErrors.location = 'Location is required';
    if (!location || location.length < 4) {
      formErrors.location = 'Location must be at least 4 characters long';
    }
    if (!propertyType) formErrors.propertyType = 'Property type is required';
    if (!priceMin || parseInt(priceMin) < 0) formErrors.priceMin = 'Min price must be a valid number';
    if (!priceMax || parseInt(priceMax) < parseInt(priceMin)) formErrors.priceMax = 'Max price must be greater than min price';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');

    if (!validate()) {
      setIsSubmitting(false);
      return;
    }

    const updatedPreferences = {
      preferredLocation: location,
      preferredPropertyTypes: [propertyType],
      preferredPriceRange: [priceMin, priceMax]
    };

    try {
      const response = await axiosInstance.post(`/users/${profileId}/updatePreferences`, updatedPreferences);
      
      if (response.status === 200) {
        setSuccessMessage(t('saved'));
        toast({
          title: t('preference-successmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    } catch (error) {
      let errorMessage = 'Failed to update preferences. Please try again later.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setErrorMessage(t('failed'));
      toast({
        title: 'Error',
        description: t('preference-errormessage'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [successMessage, errorMessage]);

  return (
    <div className="bg-primary  font-linksans">
      <div className="container px-5 py-12 min-h-svh">
        <div className="flex-col items-center flex justify-center">
        <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
        Preferences
        </h3>

      <div className="w-full flex justify-center">
      <form action="" className="w-[500px] max-w-xl flex flex-col gap-3">
            <div className="flex flex-col gap-2">
                <label className="text-sm text-paragraphColor" htmlFor="location">Location</label>
                <input placeholder="Enter Location"  className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" type="text" name="location" id="location" value ={location} onChange={(e) => setLocation(e.target.value)} />
            {errors.location && <span className="text-sm text-red-400">{errors.location}</span>}

            </div>
            <div className="flex flex-col gap-2">
              <label className="text-sm text-paragraphColor" htmlFor="propertyType">
                Property Type
              </label>
              <select 
                className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
                id="propertyType" 
                value={propertyType} 
                onChange={(e) => setPropertyType(e.target.value)}
              >
                <option value="">Select Property Type</option>
                {propertyTypeOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.propertyType && <span className="text-sm text-red-400">{errors.propertyType}</span>}
            </div>

            <div className="flex flex-col gap-2">
            <label className="text-sm text-paragraphColor" htmlFor="min-price">
              Min Price <span style={{ color: 'red' }}>*</span>
            </label>
            <input 
              type="number" 
              placeholder="Enter Min Price" 
              className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white"
              id="min-price"
              value={priceMin}
              onChange={(e) => setPriceMin(e.target.value)}
              min="0"
            />
            {errors.priceMin && <span className="text-sm text-red-400">{errors.priceMin}</span>}
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm text-paragraphColor" htmlFor="max-price">
              Max Price <span style={{ color: 'red' }}>*</span>
            </label>
            <input 
              type="number" 
              placeholder="Enter Max Price" 
              className="text-gray-500 text-sm px-3 py-3 rounded-lg bg-white"
              id="max-price"
              value={priceMax}
              onChange={(e) => setPriceMax(e.target.value)}
              min="0"
            />
            {errors.priceMax && <span className="text-sm text-red-400">{errors.priceMax}</span>}
          </div>

            <div className="flex  gap-8 items-center pt-6">
            <div className="flex-1"><button className="w-full border-gray-300 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-gray-600  border">Cancel</button></div>
                 <div className="flex-1"><button type="submit" className="w-full text-sm 2xl:text-base lg:text-base xl:text-base md:text-sm 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-paragraphColor bg-white  border" onClick={handleSubmit}>Update Preferences</button></div>
            </div>
        </form>
      </div>
        </div>
      </div>
    </div>
  );
};

export default MyPreference;
